/* Website Header Footer*/
.web-header {
  display: flex;
  align-items: center;
  min-height: 92px;
  box-shadow: 0 2px 8px #f0f1f2;
}
.web-header .navbar ul li a {
  border: 1px solid #fff;
  border-radius: 4px;
  color: #29292a;
  display: block;
  font-weight: 500;
  margin: 0;
  padding: 8px 16px;
  position: relative;
  text-decoration: none;
}
.web-header .web-logo {
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  color: #1a3652;
  text-shadow: 2px 3px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}
.web-header .web-logo .web-default-logo {
  height: 48px;
  margin-right: 8px;
}
.web-footer {
  display: flex;
  align-items: center;
  min-height: 64px;
  /* box-shadow: 0 2px 8px #f0f1f2; */
  background: #f7f7f7;
  border-top: solid 1px rgba(0, 0, 0, 0.02);
}

/* Page Title */
.web-page-title {
  /* background: #1a3652; */
  padding: 42px 0px 28px 0px;
  /* color: #fff; */
}
.web-page-title h1 {
  padding: 0px;
  margin: 0px;
  font-size: 2.2rem;
  font-weight: 700;
  color: #1a3652;
}

/* Home Title and Sub Title */
.web-home {
  margin-bottom: 100px;
}
.web-home .home-title {
  position: relative;
  font-size: 2rem;
  font-weight: 700;
  padding: 0px;
  margin: 0px 0px 8px 0px;
  color: #1a3652;
}
.web-home .home-sub-title {
  position: relative;
  font-size: 1rem;
  padding: 0px;
  margin: 0px 0px 24px 0px;
  color: rgba(0, 0, 0, 0.7);
}

/* Home Banner */
.home-banner-bg {
  position: relative;
  /* background: #000; */
  height: 560px;
}
.home-banner-bg img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  object-fit: cover;
}
.home-banner-bg::after {
  content: "";
  background: linear-gradient(
    89.28deg,
    #161515 0.5%,
    #1615159e 40%,
    #16151500 90%
  );
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
.home-banner-content {
  position: relative;
  z-index: 100;
  color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
}
.home-banner-content h2 {
  font-size: 3rem;
  font-weight: 700;
  max-width: 50%;
  margin: 0px;
  padding: 0px;
  line-height: 3.6rem;
}
.home-banner-content h5 {
  font-size: 1.2rem;
  font-weight: 500;
  max-width: 50%;
  margin: 24px 0px 0px 0px;
  padding: 0px;
  line-height: 1.8rem;
}

/* Home Deals */
.home-deals {
  margin: 48px 0px;
}
.home-deals-wrap {
  position: relative;
  height: 372px;
  box-shadow: 0 4px 4px 0 #00000040;
  border-radius: 12px;
  margin-bottom: 24px;
}
.home-deals-wrap img {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.home-deals-wrap h3 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  font-size: 1.18rem;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  min-height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 24px;
  margin: 0px;
  border-radius: 0px 0px 12px 12px;
  z-index: 101;
}
.home-deals-wrap h4 {
  position: absolute;
  left: 12px;
  top: 12px;
  font-size: 1rem;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 24px;
  margin: 0px;
  border-radius: 12px;
  z-index: 100;
}
.home-deals-title {
  position: absolute;
  bottom: 0px;
  padding: 24px 36px;
}
.home-deals-title h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1a3652;
}
.home-deals-wrap a {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  border-radius: 12px;
}
.home-deals-wrap a:hover {
  background: rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s;
}

/* Home Testimonials */
.home-testimonials {
  margin: 48px 0px;
}
.home-testimonials-wrap {
  position: relative;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  text-align: center;
  /* background: #f7f7f7; */
  margin-bottom: 24px;
  box-shadow: 0 4px 4px 0 #00000020;
  border: solid 1px rgba(0, 0, 0, 0.05);
}
.home-testimonials-wrap h3 {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0px;
  margin: 0px 0px 8px 0px;
  color: #1a3652;
}
.home-testimonials-wrap h4 {
  font-size: 1rem;
  font-weight: 500;
  padding: 0px;
  margin: 0px 0px 24px 0px;
}
.home-testimonials-wrap p {
  font-size: 0.9rem;
  padding: 0px;
  margin: 0px;
  color: rgba(0, 0, 0, 0.6);
  text-align: justify;
}

/* Home Destinations */
.home-destinations {
  margin: 48px 0px;
}
.home-destinations-wrap {
  position: relative;
  height: 272px;
  box-shadow: 0 4px 4px 0 #00000040;
  border-radius: 12px;
  margin-bottom: 24px;
}
.home-destinations-wrap img {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.home-destinations-wrap h3 {
  position: absolute;
  right: 16px;
  top: 16px;
  margin: auto auto;
  font-size: 1.2rem;
  font-weight: 600;
  background: rgba(256, 256, 256, 0.6);
  color: #1a3652;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 24px;
  margin: 0px;
  border-radius: 12px;
  z-index: 100;
}
.home-destinations-wrap a {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  border-radius: 12px;
}
.home-destinations-wrap a:hover {
  background: rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s;
}

/* Home Whyus */
.home-whyus {
  margin: 48px 0px 72px 0px;
}
.home-whyus-wrap {
  position: relative;
  border-radius: 12px;
  padding: 24px;
  text-align: center;
  margin-bottom: 24px;
  box-shadow: 0 4px 4px 0 #00000010;
  height: 100%;
  border: solid 1px rgba(0, 0, 0, 0.05);
}
.home-whyus-wrap img {
  width: 100%;
  height: 98px;
  object-fit: contain;
}
.home-whyus-wrap h3 {
  font-size: 1.3rem;
  font-weight: 700;
  padding: 0px;
  margin: 16px 0px 16px 0px;
  color: #1a3652;
}
.home-whyus-wrap h4 {
  font-size: 1rem;
  font-weight: 500;
  padding: 0px;
  margin: 0px 0px 24px 0px;
}
.home-whyus-wrap p {
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
  color: rgba(0, 0, 0, 0.6);
}

/* Details Screen */
.web-details .web-details-info {
  border-radius: 12px;
  box-shadow: 0 0 12px #00000012;
  padding: 1px;
}
.web-details .web-details-title {
  background: rgba(0, 0, 0, 0.07);
  padding: 24px;
  border-radius: 12px 12px 0px 0px;
}
.web-details .web-details-title h2 {
  font-size: 1.6rem;
  font-weight: 700;
  padding: 0px;
  margin: 0px 0px 12px 0px;
  color: #1a3652;
}
.web-details .web-details-title p {
  padding: 0px;
  margin: 0px;
}
.web-details .web-details-price {
  text-align: center;
}
.web-details .web-details-price h3 {
  font-size: 1.8rem;
  font-weight: 700;
  padding: 0px;
  margin: 0px;
  color: #1a3652;
}
.web-details-info-wrap {
  padding: 24px;
}
.web-details-info-wrap h5 {
  font-size: 1.1rem;
  line-height: 28px;
  font-weight: 600;
  padding: 0px;
  margin: 0px 0px 24px 0px;
  text-align: center;
}
.web-details .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px 8px 12px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
}
.web-details .btn img {
  margin-right: 12px;
  height: 32px;
}
.web-details .btn-whatsapp {
  background: #25d366;
}
.web-details .btn-whatsapp:hover {
  background: #1cb454;
  color: #fff;
}
.web-details .btn-email {
  background: #1a3652;
}
.web-details .btn-email:hover {
  background: #122a42;
  color: #fff;
}
.web-details-support {
  border-top: solid 1px rgba(0, 0, 0, 0.05);
  padding: 16px 0px 8px 0px;
  margin: 16px 0px;
}
.web-details-support h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0px 0px 12px 0px;
  padding: 0px;
}
.web-details-support a {
  text-decoration: none;
}
.web-details .web-details-image {
  position: relative;
}
.web-details .web-details-image img {
  width: 100%;
  height: 440px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 0 12px #00000012;
  margin-bottom: 24px;
}
.web-details .web-details-image h2 {
  position: absolute;
  left: 16px;
  top: 16px;
  margin: auto auto;
  font-size: 1.2rem;
  font-weight: 600;
  background: rgba(256, 256, 256, 0.6);
  color: #1a3652;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 24px;
  margin: 0px;
  border-radius: 12px;
  z-index: 100;
}
.web-details .web-details-sec {
  border-radius: 12px;
  box-shadow: 0 0 8px #00000012;
  padding: 24px 24px;
  margin-bottom: 24px;
}
.web-details .web-details-sec h3 {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0px;
  margin: 0px 0px 16px 0px;
  color: #1a3652;
}
.web-details-inquiry {
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 0 12px #00000012;
}
.web-details-inquiry h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0px 0px 24px 0px;
  padding: 0px;
  color: #1a3652;
}
.web-details-inquiry button {
  height: 40px;
}
.web-details-inquiry .btn-inquiry-add {
  background: #1a3652;
}

/* Destination Details */
.destination-details .web-details-title h2 {
  padding: 0px;
  margin: 0px;
}

/* Quick Proposal */
.web-details.quick-proposal-details h4 {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0px;
  margin: 0px 0px 16px 0px;
  color: #1a3652;
}
.web-details.quick-proposal-details h2 {
  font-size: 1.2rem;
  padding: 0px;
  margin: 0px;
}
.web-details.quick-proposal-details textarea {
  min-height: 148px;
  border: solid 1px rgb(200, 214, 226);
  background: rgb(239, 248, 255);
}
.web-details.quick-proposal-details button {
  height: 48px;
  width: 100%;
}

@media (max-width: 575px) {
  .web-header .navbar {
    display: none;
  }
  .home-banner-bg {
    height: 420px;
  }
  .home-banner-content {
    text-align: center;
  }
  .home-banner-content h2 {
    font-size: 2rem;
    line-height: 2.8rem;
    max-width: none;
  }
  .home-banner-content h5 {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.8rem;
    max-width: none;
  }
  .home-deals,
  .home-destinations,
  .home-testimonials,
  .home-whyus {
    margin: 24px 0px;
    text-align: center;
  }
  .home-testimonials-wrap {
    height: auto;
  }
  .home-whyus-wrap {
    height: auto;
  }
  .web-details-right-col {
    order: 1;
  }
  .web-details-left-col {
    order: 2;
  }
  .web-details-btns .btn-whatsapp {
    display: none !important;
  }
  .web-details-btns .btn-whatsapp-mobile {
    display: block !important;
    margin-bottom: 16px;
  }
  .web-details-btns .col-lg-6 {
    padding: 0px !important;
  }
}
