.public-home {
  margin: 42px 0px;
}

.public-home .public-home-banner {
  min-height: 540px;
  display: flex;
  align-items: center;
  /* background: rgba(0, 0, 0, 0.02); */
}
.public-home .public-home-banner-bg {
  position: absolute;
  left: 0px;
  top: 72px;
  width: 100%;
}
.public-home .public-home-banner .container {
  position: relative;
  z-index: 1000;
}
.public-home .public-home-banner h2 {
  font-size: 2.8rem;
  font-weight: 700;
  padding: 0px;
  margin: 0px 0px 24px 0px;
  color: #fff;
  text-align: left;
}
.public-home .public-home-banner h3 {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0px 0px 24px 0px;
  margin: 0px 0px 24px 0px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  color: #fff;
}
.public-home .public-home-banner ul {
  font-size: 1.2rem;
  list-style: none;
  padding: 0px;
  margin: 0px;
  color: #fff;
}
.public-home .public-home-banner ul li {
  font-size: 1.1rem;
  font-weight: 500;
  list-style: none;
  padding: 0px 0px 0px 42px;
  margin: 0px 0px 12px 0px;
  position: relative;
}
.public-home .public-home-banner ul li::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 4px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: url(../images/public//check-icon.png) center center no-repeat;
}

.public-home h2 {
  font-size: 2.8rem;
  font-weight: 700;
  padding: 0px;
  margin: 0px 0px 16px 0px;
  color: #1a3652;
  text-align: center;
}
.public-home h5 {
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0px;
  margin: 0px 0px 24px 0px;
  color: #696969;
  text-align: center;
}

.public-home-users {
  margin-top: 162px;
}

.public-home-users .public-home-users-wrap {
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 24px 24px;
  text-align: center;
}
.public-home-users .public-home-users-wrap h5 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0px 0px 16px 0px;
  padding: 0px;
  line-height: 28px;
}

.public-home-users .public-home-users-wrap-1 {
  border: solid 1px #f9a101;
}
.public-home-users .public-home-users-wrap-2 {
  border: solid 1px #00cc5c;
}
.public-home-users .public-home-users-wrap-3 {
  border: solid 1px #06bbf1;
}
.public-home-users .public-home-users-wrap-4 {
  border: solid 1px #7501f9;
}

.public-home .public-home-modules {
  margin-top: 82px;
}
.public-home .public-home-modules h2 {
  font-size: 2.5rem;
  text-align: left;
  padding: 0px;
  margin: 0px 0px 36px 0px;
}
.public-home .public-home-modules h3 {
  font-size: 1.3rem;
  font-weight: 400;
  padding: 0px;
  margin: 0px 0px 24px 0px;
  line-height: 36px;
}
.public-home .public-home-modules h4 {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0px;
  margin: 0px 0px 16px 0px;
  line-height: 32px;
}
.public-home .public-home-modules ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.public-home .public-home-modules ul li {
  font-size: 1.1rem;
  font-weight: 500;
  list-style: none;
  padding: 0px 0px 0px 42px;
  margin: 0px 0px 12px 0px;
  position: relative;
}
.public-home .public-home-modules ul li::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 4px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: url(../images/public//check-icon.png) center center no-repeat;
}
.public-home .public-home-modules img {
  width: 100%;
}

@media (max-width: 575px) {
  .public-home .public-home-banner ul {
    display: none;
  }
  .public-home .public-home-banner-bg {
    height: 402px;
    object-fit: cover;
  }
  .public-home .public-home-banner h2 {
    font-size: 2rem;
    text-align: center;
  }
  .public-home .public-home-banner h3 {
    text-align: center;
  }
  .public-home .public-home-banner {
    min-height: 368px;
  }
  .public-home-users {
    margin-top: 28px;
  }
  .public-home-users .public-home-users-wrap {
    margin-bottom: 16px;
  }
  .public-home h2 {
    font-size: 2.2rem;
  }
  .public-home h5 {
    font-size: 1.2rem;
  }
  .public-home .public-home-modules h2 {
    font-size: 2.2rem;
  }
  .public-home .public-home-modules img {
    display: none;
  }
}
